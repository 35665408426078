import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
// import store from '@/store'
// eslint-disable-next-line
let reqUrl = ''
// 创建axios实例
console.log(process.env)
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000 // 请求超时时间
  // withCredentials: true
})

service.interceptors.request.use(config => {
  const url = config.url
  reqUrl = config.url

  if (!url) return config

  // if (process.env.NODE_ENV === 'development') {
  const token = sessionStorage.getItem('Authorization')
  // }
  if (!token) {
    router.replace({
      path: '/login'
    })
    // router.push({ name: 'pageMain' })
  } else {
    config.headers.Authorization = token
  }

  return config
}, error => {
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  const code = response.status
  const data = response.data || response.data.data
  if (data.code === 401) {
    sessionStorage.removeItem('Authorization')
    router.replace({
      path: '/login'
    })
    return false
  }
  if (!navigator.onLine) {
    Message.warning('网络已断开，请检查网络！')
    return Promise.reject(response.data)
  }

  if (code !== 200) {
    return Promise.reject(response.data)
  }

  return data
}, error => {
  return Promise.reject(error)
})

export const get = (url, ...params) => service.get(url, ...params).catch(err => { console.log(err); return Promise.reject(err) })
export const post = (url, ...params) => service.post(url, ...params).catch(err => { console.log(err); return Promise.reject(err) })
export const upload = (url, ...params) => service.post(url, ...params, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}).catch(err => { console.log(err); return Promise.reject(err) })

export default service
